/**
 * Library to manage configuration and consents
 * of Google Analytics 4
 *
 * @author    João Castro <jcastro@portoeditora.pt>
 * @copyright 2021 Porto Editora
 */

 var pt = pt || {};
 pt.gpe = pt.gpe || {};
 pt.gpe.generic = pt.gpe.generic || {};
 pt.gpe.generic.ga4 = pt.gpe.generic.ga4 || {};
 
 /**
  * This function needs to be loaded before GA4 script (either GA.js or GTM.js).
  * Consent management must be already ready.
  * 
  * GA4 considers two types of consent information: 'config' and 'update'.
  * 'config' - is only accepted if its uploaded to window.dataLayer before GA4 script loaded
  * 'update' - is only accepted if its uploaded to window.dataLayer after GA4 script loaded
  */
 pt.gpe.generic.ga4 = function () {
 
     var GA4_ID;
     var DENIED = "denied";
     var ACCEPTED = "granted";
     window.dataLayer = window.dataLayer || [];
 
     /**
      * Creates gtag function to populate
      * data layer with events
      */
     function gtag() { dataLayer.push(arguments); }
 
     /**
      * Init gtag definitions on data layer
      * with start RGPD configs and sets
      * GA ID
      * 
      * @param {Boolean} checkConsent - checks consent on start
      * @param {string} ga_id - analytics id
      */
     function init(checkConsent, ga_id) {
         GA4_ID = ga_id;
         checkConsent ? acceptAll() : denyConsent(true, true, true, null);
     }
 
     /**
      * Default accept consent must be setted before loading
      * any GA4 script (either GA.js or GTM.js)
      */
     function configAccept() {
         gtag("consent", "config", {
             'ad_storage': 'granted',
             'analytics_storage': 'granted'
         });
     }
 
     /**
      * Default deny consent must be setted before loading
      * any GA4 script (either GA.js or GTM.js)
      */
     function configDeny() {
         gtag("consent", "config", {
             'ad_storage': 'denied',
             'analytics_storage': 'denied'
         });
     }
 
     /**
      * Generic function to accept
      * all permissions on GA4
      */
     function acceptAll() {
         gtag("consent", "update", {
             'ad_storage': 'granted',
             'analytics_storage': 'granted'
         });
     }
 
     /**
      * Method to deny consent
      * and manage tracking control
      * 
      * It has ad_storage, analytics_storage
      * and ads_data_redaction ready by default
      * to manage
      * 
      * @param {boolean} keepAnonTracking - true to keep track
      * @param {boolean} ad_storage - true if denied
      * @param {boolean} analytics_storage - true if denied
      * @param {boolean} ads_data_redaction - true if denied
      * @param {Array<string>} other_consent - if has value, it will be denied
      */
     function denyConsent(keepAnonTracking, ad_storage, analytics_storage, other_consent) {
         var consent_configurator = {};
 
         !keepAnonTracking ? window['ga-disable-' + GA4_ID] = true : null;
 
         ad_storage ? consent_configurator['ad_storage'] = DENIED : null;
         analytics_storage ? consent_configurator['analytics_storage'] = DENIED : null;
         other_consent && other_consent.forEach(function (consent, i) { consent_configurator[consent] = DENIED; });
 
         gtag("consent", "update", consent_configurator);
     }
 
     /**
      * Method to accept some consents
      * 
      * It has ad_storage, analytics_storage
      * and ads_data_redaction by default ready
      * to manage
      * 
      * @param {boolean} ad_storage - true to accept consent
      * @param {boolean} analytics_storage - true to accept consent
      * @param {boolean} ads_data_redaction - true to accept consent
      * @param {Array<string>} other_consent - if has value, it will be accepted
      */
     function acceptConsent(ad_storage, analytics_storage, other_consent) {
         var consent_configurator = {};
 
         ad_storage ? consent_configurator['ad_storage'] = ACCEPTED : null;
         analytics_storage ? consent_configurator['analytics_storage'] = ACCEPTED : null;
         other_consent && other_consent.forEach(function (consent, i) { consent_configurator[consent] = ACCEPTED; });
 
         gtag("consent", "update", consent_configurator);
     }
 
     /**
      * Method to update specific consents
      * 
      * @param {Object} consents - consent object
      * @param {String} consents.name - consent name
      * @param {String} consent.status - consent config - 'granted' or 'denied'
      * @param {Array<string>} regions - for specific consents to some regions - eg.: ['ES', 'PT']
      */
     function updateConsent(consents, regions) {
         var consent_configurator = {};
 
         consents.forEach(function (consent) { consent_configurator[consent.name] = consent.status; });
         regions ? consent_configurator['regions'] = regions : null;
 
         gtag("consent", "update", consent_configurator);
     }
 
     return {
         init: init,
         configAccept: configAccept,
         configDeny: configDeny,
         acceptAll: acceptAll,
         denyConsent: denyConsent,
         acceptConsent: acceptConsent,
         updateConsent: updateConsent,
         gtag: gtag
     }
 }();
 
 /**
  * Function to help converting old generic GA 
  * ecommerce functions to the new GA4 API
  */
 pt.gpe.generic.ga4.ecommerce_actions = function () {
 
     var EUR_CURRENCY = "EUR";
 
     /**
      * Method that maps old checkout_option event
      * to GA4 available API
      * 
      * Old checkout_option event maps to new
      * add_shipping_info and add_payment_info
      * 
      * @param {Object} dataLayer - dataLayer where analytics info is being pushed
      * @param {Boolean} updatedShippingOption - true if user updated shipping options
      * @param {Boolean} updatedPayment - true if user updated order payment
      * @param {Object} shippingInfo - shipping info to post to analytics
      * @param {Object} paymentInfo - payment info to post to analytics
      */
     function convertSendCheckoutDetails(dataLayer, updatedShippingOption, updatedPayment, shippingInfo, paymentInfo) {
 
         updatedShippingOption && updatedPayment
             ? dataLayer.push(
                 {
                     'event': 'add_shipping_info',
                     'ecommerce': {
                         'checkout_option': {
                             'actionField': { 'option': shippingInfo }
                         }
                     }
                 },
                 {
                     'event': 'add_payment_info',
                     'ecommerce': {
                         'checkout_option': {
                             'actionField': { 'option': paymentInfo }
                         }
                     }
                 }
             )
             : dataLayer.push({
                 'event': updatedShippingOption ? 'add_shipping_info' : 'add_payment_info',
                 'ecommerce': {
                     'checkout_option': {
                         'actionField': { 'option': updatedShippingOption ? shippingInfo : paymentInfo }
                     }
                 }
             })
     }
 
     /**
      * Method that sends data to add_to_wishlist tracking
      * 
      * @param {String} evento - event name listened on dataLayer
      * @param {Array} dataLayer - dataLayer created to communicate with GTM
      * @param {ProductPortlet} product - product object with title, subart and price
      * @param {Integer} quantity - quantity added
      */
     function addToWishlist(evento, dataLayer, product, quantity) {
         dataLayer.push({
             'event': evento,
             'currency': EUR_CURRENCY,
             'ecommerce': {
                 'items': [
                     {
                         'item_name': product.title,
                         'item_id': product.subart,
                         'price': product.price,
                         'quantity': quantity
                     }
                 ]
             }
         });
     }
 
     function addToCart(event, product, quantity) {
         dataLayer.push({ 
             'event': event,
             'currency': EUR_CURRENCY,
             'value': 1, // Company's policies
             'items': [
                 {
                     'item_name': product.title,
                     'item_id': product.subart,
                     'price': 1,
                     'quantity': quantity
                 }
             ]
         })
     }
 
     function purchase(event, items, transaction_id) {
         dataLayer.push({
             'event': event,
             'currency': EUR_CURRENCY,
             'value': 1, // Company's policies
             'items': [
                 {
                     'item_name': product.title,
                     'item_id': product.subart,
                     'price': 1,
                     'quantity': product.quantity
                 }
             ]
         })
     }
 
     return {
         convertSendCheckoutDetails: convertSendCheckoutDetails,
         addToWishlist: addToWishlist,
         addToCart: addToCart,
         purchase: purchase
     }
 }();