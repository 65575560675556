modules.header = (function () {

    var menuOptions = {
        showMenuSelector: '.show-menu',
        hideMenuSelector: '.hide-menu',
        menuSelector: 'body>header .links-container',
        menuLinksSelector: 'body>header .links-container .links',
        menuHoverSelector: 'body>header .links-container .lvl-0, header .links-container .links .menu',
        menuOptionOpenClass: 'open',
        arrowSelector: '.arrow',
        arrowOpenedClass: 'icon-v',
        arrowClosedClass: 'icon-V',
        hiddenElementClass: 'hidden-portable',
        togglingClass: 'toggling'
    },
    searchOptions = {
        searchToggleSelector: 'header .toggle-search',
        searchContainerSelector: '#header-search',
        searchFormSelector: '#header-search form#search-form',
    },
    cartOptions = {
        preventTimeoutCloseClass: '"js-timeout-close-prevent"'
    };

    function _init() {
        $(document).ready(function () {
            _initHeaderMenu();
            _initSearch();
            _initCart();
            _initLazyLoad();
            _initOverlayCloseContent();
        });
    }

    function _initOverlayCloseContent() {

        var headerElem = $('body>header');

        $('body>header>nav .overlay').on('click', function (e) {
            var clickedElement = $(e.target);

            if($('#shopping-cart-popup-outer').is(":visible") && clickedElement.closest("#shopping-cart-popup-container").size() === 0) {
                e.preventDefault();
                e.stopPropagation();
                closeCart();
            }

            if (headerElem.hasClass('search-open') && clickedElement.closest("#search-container").size() === 0 && clickedElement.closest("header .toggle-search").size() === 0) {
                closeSearch();
            }

        });

        $(document).click(function (e) {
            var clickedElement = $(e.target);
            if (headerElem.hasClass('search-open') && clickedElement.closest("#search-container").size() === 0 && clickedElement.closest("header .toggle-search").size() === 0) {
                closeSearch();
            }
        });
    }

    function _initLazyLoad() {
        $("body>header nav").on("scroll", function(){
            $(document).trigger("vo-lazy-check");
        });
    }

    function _initCart() {
        var shoppingCartHeaderBtn = $("#shopping-cart-header-btn");
        if ($(".js-header-cart-prevent").size() === 0) {
            shoppingCartHeaderBtn.click(function(e) {
                var bodyElem = $("body");
                if (!bodyElem.hasClass("cart-open")) {
                    var openCallback = function () {
                        var callback = function callback() {
                            var openCartContainer = function openCartContainer() {
                                openCart();
                            }
                            closeSearch(false, openCartContainer);
                        }
                        closeMenu(callback);
                    };
                    updateSmallShoppingCart(openCallback);
                } else {
                    closeCart();
                }
            });
        } else {
            shoppingCartHeaderBtn.addClass("disabled");
        }


    }

    function _initSearch() {
        var elem = $(searchOptions.searchContainerSelector),
                searchInputField = $('#search-form-query'),
                searchToggleElement = $(searchOptions.searchToggleSelector),
                bodyElem = $("body"),
                headerElem = $('body>header');

        $(searchOptions.searchToggleSelector).on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            /* Because IOS only triggers focus on elements when events are triggered by user on visible elements, focus had to be done this way */
            elem.addClass('js-ios-focus-workaround').removeClass('hidden');
            searchInputField.focus();
            elem.removeClass('js-ios-focus-workaround').addClass('hidden');
            /* End of IOS focus event */

            var callback = function callback() {
                var openSearch = function openSearch(){
                    //if (elem.hasClass('hidden') && !elem.hasClass('js-hidden-focusout') && !headerElem.hasClass('search-open')) { //Older Implementation
                    if (elem.hasClass('hidden') && !headerElem.hasClass('search-open')) {
                        headerElem.addClass('search-open');
                        searchToggleElement.addClass('open');
                        bodyElem.addClass('lock');
                        elem.removeClass('hidden');
                    } else {
                        closeSearch(true);
                    }
                    searchInputField.focus();
                }
                closeCart(openSearch);
            };

           closeMenu(callback);

        });

        _initAutocomplete();
    }

    function closeSearch(hideOverlay, callback) {
        if(typeof hideOverlay === "undefined") {
            hideOverlay = true;
        }

        var elem = $(searchOptions.searchContainerSelector),
            searchToggleElement = $(searchOptions.searchToggleSelector),
            bodyElem = $("body");

        // Let the browser set focus on the newly clicked element before check
        searchToggleElement.removeClass('open');
        $('header').removeClass('search-open');
        elem.addClass('hidden').addClass('js-hidden-focusout');

        if(hideOverlay){
            bodyElem.removeClass('lock');
        }

        //Hide workaround for icon top bar when already open
        setTimeout(function () {
            elem.removeClass('js-hidden-focusout');
        }, 200);

        //Clears field
        $("#search-form-query").val("");
        $('#search-form-query').trigger('input');

        if(typeof callback === "function") {
            callback();
        }
    }

    function _initHeaderMenu() {

        $(menuOptions.showMenuSelector).on('click', function () {
            var callback = function callback() {
                closeSearch(false);
                modules.menu.openMenu(menuOptions);
            }
            closeCart(callback);
        });

        $(menuOptions.hideMenuSelector).on('click', function () {
            modules.menu.closeMenu(menuOptions);
        });

        $(menuOptions.menuSelector + " [data-id]").on('click', function () {
            modules.menu.menuOptionClick(menuOptions, this, true);
            if ($(this).attr('data-id') === "6.0") {
                modules.menu.closeMenu(menuOptions);
            }
        });

        //Hide menu when user taps outside menu
        $("body").click(function (e) {
            var clickedElement = $(e.target);
            if (modules.main.getDevice() !== "desktop" && clickedElement.closest('.links').length === 0) {
                modules.menu.closeMenu(menuOptions);
            }
        });

        $(".show-menu, header.links").on("click", function (e) {
            e.stopPropagation();
        });

        $(menuOptions.menuHoverSelector).hover(
                function () {
                    if (modules.main.getDevice() === "desktop") {
                        var element = $(this);
                        if (element.hasClass("menu")) {
                            element.prev().addClass('hover-child');
                        } else {
                            setTimeout(function () {
                                if (element.is(":hover")) {
                                    //Close Search
                                    closeSearch();
                                    $('.hover, .hover-child').removeClass('hover').removeClass('hover-child');
                                    element.addClass('hover');
                                }
                            }, 150);
                        }
                    }
                },
                function () {
                    if (modules.main.getDevice() === "desktop") {
                        var element = $(this);
                        if (element.hasClass("menu")) {
                            setTimeout(function () {
                                if (!element.is(":hover") && !element.prev().is(":hover")) {
                                    element.prev().removeClass('hover-child');
                                }
                            }, 200);
                        } else {
                            setTimeout(function () {
                                element.removeClass('hover');
                            }, 200);
                        }
                    }
                }
        );
    }

    function _initAutocomplete() {

        var input = $('[data-autocomplete-search-url]'),
            autocompleteUrl = input.data('autocomplete-search-url'),
            resultsContainerSelector = "#search-autocomplete-container-results",
            resultsContainer = $(resultsContainerSelector),
            base = resultsContainer.data('base');

            input.autocomplete({
                minLength: 3,
                appendTo: resultsContainerSelector,
                position: {
                    my: "center",
                    at: "center",
                    of: resultsContainerSelector
                },
                source: function(request, response) {
                    $.ajax({
                        url: autocompleteUrl,
                        method: 'get',
                        data: {
                            q: request.term
                        },
                        success: function (data) {

                            var values = [];

                            // Invalid data?
                            if (!data || !data.authors || !data.books){
                                return response(values);
                            }

                            var i = 0;
                            for (i = 0; i < data.books.length; i++) {

                                values.push({
                                    'value': data.books[i].id,
                                    'label': modules.main.stripTags(data.books[i].title),
                                    'id': data.books[i].id,
                                    'title': data.books[i].title,
                                    'url': '/produtos/ficha/' + data.books[i].slug + '/' + data.books[i].id,
                                    'type': 'book',
                                });
                            }

                            for (i = 0; i < data.authors.length; i++) {

                                values.push({
                                    'value': data.authors[i].id,
                                    'label': modules.main.stripTags(data.authors[i].title),
                                    'id': data.authors[i].id,
                                    'title': data.authors[i].title,
                                    'slug': data.authors[i].slug,
                                    'url': '/autor/' + data.authors[i].slug + '/' + data.authors[i].id,
                                    'type': 'author'
                                });
                            }

                            return response(values);
                        }
                    });
                },
                select: function(event, ui){
                    input.val(ui.item.label);
                    window.location.href = ui.item.url;
                    return false;
                },
                focus: function(event, ui){
                    $(this).attr("data-val", ui.item.label);
                    return false;
                }
            })
            .on("keydown", function(e) {
                if (e.keyCode == 38 || e.keyCode == 40) {
                    $(this).val($(this).attr("data-val"));
                }
            })
            .autocomplete( "instance" )
                ._renderItem = function( ul, item ) {

                var itemHtml = base
                    .replace('{title}', item.title)
                    .replace('{url}', item.url);

                if(item.type == 'book'){
                    itemHtml = itemHtml.replace('{category}', 'Livros');
                }else if(item.type == 'author'){
                    itemHtml = itemHtml.replace('{category}', 'Autores');
                }

                return $($.parseHTML(itemHtml)).appendTo(ul);

            };

        /**
         * Show hide the Clear btn based if the input is filled or not.
         */
        $(document).on('input', '#search-form-query', function(e){

            // If we have characters then display the clear link
            if($(this).val().length > 0){
                $('#clear-search-form').addClass('enabled');
            }else{
                $('#clear-search-form').removeClass('enabled');
            }
        });

        /**
         * On click on the clear link, clear the search input.
         */
        $(document).on('click', '#clear-search-form', function(e){
            e.preventDefault();
            input.val('');
            input.focus();
            input.trigger('input');
        })

    }

    function closeMenu(callback){
        modules.menu.closeMenu(menuOptions, callback);
    }

    function calculateCartItemsHeight() {
        if (modules.main.getDevice() !== "mobile") {
            var popupOuter = $("#shopping-cart-popup-outer");

            var maxHeight = $(window).outerHeight() - (popupOuter.outerHeight() - $(".small-shopping-cart-item").outerHeight()) - 50; //50 = header height / Unnecessary calculating it
            if (maxHeight > 0) {
                $(".small-shopping-cart-item").css("max-height", maxHeight + "px");
            }
        }
    }

    function cartPreventTimeoutClose(){
        $('#shopping-cart-popup-outer').addClass(cartOptions.preventTimeoutCloseClass);
    }

    function openCart() {

        var bodyElem = $("body");
        if (!bodyElem.hasClass("cart-open")) {
            var hasItems = $.trim($('span.total-cart-items').html()) !== '';

            if(hasItems) {
                $("#shopping-cart-popup-no-items").hide();
            }

            bodyElem.addClass('lock cart-open');
            $('#shopping-cart-popup-outer').attr("data-open-time", Date.now() ).slideDown(400, function(){
                modules.header.calculateCartItemsHeight();
            });
        }

    }

    /**
     *
     * @param callback function A function called after closing cart.
     * @param checkCloseTimeout bool Whether should check if has class to prevent it from closing.(Eg: Cart opens when product is added and closes 4s after if quantity is not selected)
     */
    function closeCart(callback, checkCloseTimeout, openTimeCheck) {
        var cartPopup = $('#shopping-cart-popup-outer');
        if (typeof checkCloseTimeout === "undefined" || checkCloseTimeout !== true  || (!cartPopup.hasClass(cartOptions.preventTimeoutCloseClass)) && openTimeCheck === cartPopup.attr("data-open-time")) {
            var bodyElement = $("body");
            cartPopup.removeClass(cartOptions.preventTimeoutCloseClass)
            $('#shopping-cart-popup-outer').slideUp(function(){
                bodyElement.removeClass("cart-open");
                if (typeof callback === "function") {
                    callback();
                } else {
                    bodyElement.removeClass('lock');
                }
            });
        }

    }

    function _isSearchOpen() {
        return !$(searchOptions.searchContainerSelector).hasClass("hidden");
    }

    _init();

    return {
        closeMenu: closeMenu,
        closeSearch: closeSearch,
        openCart: openCart,
        closeCart: closeCart,
        cartPreventTimeoutClose: cartPreventTimeoutClose,
        calculateCartItemsHeight: calculateCartItemsHeight
    };

})();
