modules.video = (function () {

    function getFixedUrl(url){

        // Get the details of the URL
        var isVideo = modules.thumbnail.videoInfo(url) || {};

        if (isVideo.youtube) {
            return 'https://www.youtube.com/embed/'+isVideo.youtube[1];
        } else if (isVideo.vimeo) {
            return 'https://player.vimeo.com/video/'+isVideo.vimeo[1];
        }

        return url;

    }

    function fixIframeUrl(iframe){
        iframe.attr('src', getFixedUrl(iframe.attr('src')));
    }

    function _init() {
        $(document).ready(function () {
            $('iframe:not(.no-rel)').each(function(i, obj) {
                fixIframeUrl($(this));
            });
        });
    }

    _init();

    return {
        fixIframeUrl: fixIframeUrl
    };

})();
