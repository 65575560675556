modules.form = (function () {

    function _handleFormSuccess(form, successContainer, errorContainer, toggleEffect, resetForm, duration, scrollToTarget){

        form.trigger('ajax-form-submit:auto-handle:start', [1]);

        // Show the success container
        successContainer.fadeIn('slow');
        // Hide the error container
        errorContainer.hide();

        if(resetForm){
            // Clear the form
            resetFormFields(form);
        }

        if(toggleEffect){
            form.hide();
        }

        if(scrollToTarget){
            modules.main.scrollToElement(scrollToTarget);
        }

        if(toggleEffect){
            // Hide the form and after X seconds display it again
            setTimeout(function () {
                form.trigger('ajax-form-submit:auto-handle:end', [1]);
                successContainer.hide();
                form.show();
            }, duration);
        }

    }

    function _handleFormFail(form, successContainer, errorContainer, toggleEffect, resetForm, duration, scrollToTarget){

        form.trigger('ajax-form-submit:auto-handle:start', [0]);

        // Show the error container
        errorContainer.fadeIn('slow');
        // Hide the success container
        successContainer.hide();

        if(resetForm){
            // Clear the form
            resetFormFields(form);
        }

        if(toggleEffect){
            form.hide();
        }

        if(scrollToTarget){
            modules.main.scrollToElement(scrollToTarget);
        }

        if(toggleEffect){
            // Hide the form and after X seconds display it again
            setTimeout(function () {
                form.trigger('ajax-form-submit:auto-handle:end', [0]);
                errorContainer.hide();
                form.show();
            }, duration);
        }

    }

    /**
     * Extracts the errors from the API Response.
     */
    function getErrors(obj) {
        var errors = [];
        for (var i in obj) {
            if($.isArray(obj[i])){
                if(i == 'errors'){
                    for(var e in obj[i]){
                        errors.push(obj[i][e])
                    }
                }
            }else{
                errors = errors.concat(getErrors(obj[i]));
            }
        }
        return errors;
    }

    function ajaxSelectRemoteOptionsLoad(select, url, callback){

        var handleLoader = (typeof select.attr('data-loader') !== typeof undefined && select.attr('data-loader') !== false) && (typeof select.attr('data-container') !== typeof undefined && select.attr('data-container') !== false);
        var handleFail = (typeof select.attr('data-fail') !== typeof undefined && select.attr('data-fail') !== false) && (typeof select.attr('data-container') !== typeof undefined && select.attr('data-container') !== false);

        if(handleFail){
            $(select.attr('data-fail')).addClass('hidden');
            $(select.attr('data-container')).removeClass('hidden');
        }

        if (handleLoader) {
            $(select.attr('data-loader')).removeClass('hidden');
            $(select.attr('data-container')).addClass('hidden');
        }

        $.getJSON(url, function(data) {
            if(handleLoader){
                $(select.attr('data-loader')).addClass('hidden');
                $(select.attr('data-container')).removeClass('hidden');
            }
            callback(data);
        }).fail(function() {
            if(handleLoader){
                $(select.attr('data-loader')).addClass('hidden');
            }
            if(handleFail){
                $(select.attr('data-fail')).removeClass('hidden');
            }
        });

    };

    function _initAjaxFormSubmit(){

        $('[data-ajax-form-submit][data-no-js-validation]').on('submit', function(e){
            ajaxFormSubmit($(this));
            return false;
        });

    }

    function ajaxFormSubmit(form){

        var formData = new FormData(form[0]);

        var autoHandle = typeof form.attr('data-auto-handle') !== typeof undefined && form.attr('data-auto-handle') !== false;
        var successContainer = $(form.attr('data-sucess-container'));
        var errorContainer = $(form.attr('data-error-container'));
        var errorsContainer = $(form.attr('data-errors-container'));
        var scrollToTarget = (typeof form.attr('data-scrollto-target') !== typeof undefined && form.attr('data-scrollto-target') !== false) ? form.attr('data-scrollto-target') : false;

        var toggleEffect = true;
        if(typeof form.attr('data-no-toggle-effect') !== typeof undefined && form.attr('data-no-toggle-effect') !== false){
            toggleEffect = false;
        }
        var resetForm = true;
        if(typeof form.attr('data-no-reset-form') !== typeof undefined && form.attr('data-no-reset-form') !== false){
            resetForm = false;
        }

        var duration = form.attr('data-duration');

        var successDuration = 3000;
        var failDuration = 3000;

        if(form.attr('data-duration')){
            successDuration = form.attr('data-duration');
            failDuration = form.attr('data-duration');
        }

        if(form.attr('data-success-duration')){
            successDuration = form.attr('data-success-duration');
        }
        if(form.attr('data-error-duration')){
            failDuration = form.attr('data-error-duration');
        }

        var trackRegistration = (typeof form.attr('data-track-registration') !== typeof undefined && form.attr('data-track-registration') !== false) ? form.attr('data-track-registration') : false;

        $.ajax({
                type: form.attr('method'),
                url: form.attr('action'),
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                enctype: 'multipart/form-data',
                dataType: 'json',
                beforeSend: function (xhr) {
                    if(autoHandle) {
                        // Clear the Errors
                        errorsContainer.empty().hide();
                        // Hide the success container
                        successContainer.hide();
                        // Hide the error container
                        errorContainer.hide();
                    }
                    form.trigger('ajax-form-submit:beforesend', [xhr]);
                }
            })
            .done(function (data, textStatus, jqXHR) {

                if(autoHandle){
                    _handleFormSuccess(form, successContainer, errorContainer, toggleEffect, resetForm, successDuration, scrollToTarget);
                }
                if(trackRegistration){
                    modules.facebookPixelTracking.completeRegistration(trackRegistration, true);
                }

                form.trigger('ajax-form-submit:done', [data, textStatus, jqXHR]);
            })
            .fail(function (jqXHR, textStatus, errorThrown) {

                if(trackRegistration){
                    modules.facebookPixelTracking.completeRegistration(trackRegistration, false);
                }
                if(autoHandle){

                    if(jqXHR.hasOwnProperty('responseJSON')){

                        var responseJSON = jqXHR.responseJSON;

                        // Check for form errors, and show them
                        if(responseJSON.hasOwnProperty('code') && responseJSON.code != 500){

                            if(responseJSON.hasOwnProperty('errors')){
                                var errors = getErrors(responseJSON.errors);

                                // We obtained errors?
                                if (errors.length) {
                                    for(var e in errors){

                                        $('<li/>', {
                                            'text': errors[e],
                                            'class': 'error'
                                        }).appendTo(errorsContainer);

                                    }

                                    regenerateFormCaptchas(form);
                                    errorsContainer.show();

                                }else{
                                    _handleFormFail(form, successContainer, errorContainer, toggleEffect, resetForm, failDuration, scrollToTarget);
                                }
                            }else{
                                _handleFormFail(form, successContainer, errorContainer, toggleEffect, resetForm, failDuration, scrollToTarget);
                            }
                        }else{
                            _handleFormFail(form, successContainer, errorContainer, toggleEffect, resetForm, failDuration, scrollToTarget);
                        }
                    }

                }
                form.trigger('ajax-form-submit:fail', [jqXHR, textStatus, errorThrown]);
            })
            .always(function (data, textStatus, jqXHR) {
                form.trigger('ajax-form-submit:always', [data, textStatus, jqXHR]);
            });

        return false;

    }

    function regenerateFormCaptchas(form){
        form.find('.realperson-regen').click();
        form.find('.captcha-input-js').val('');
    }

    function resetFormFields(form) {
        form[0].reset();

        var select2Container = form.find('select.select2');

        if(select2Container.size()) {
            // Destroy
            select2Container.select2("destroy");
            // Init
            modules.main.initSelect2(select2Container);
        }

        form.find('[data-count-remaining-chars-target]').each(function() {
            modules.main.resetRemainingCharacters($(this));
        });
        form.find('.upload-trigger-btn').each(function() {
            $(this).text($(this).attr('data-i-text'));
        });

        regenerateFormCaptchas(form);

        if(form.data('validation')){
            form.validate().resetForm(); // Resets validations
        }

    }

    function resetSectionFormFields(section) {
        section.children().each(function() {
            $(this).find('input').val('');
            $(this).prop('checked', false);
        });
    }

    function _focusPlaceholderBehaviour(){

        // Make placeholders disappear on focus //
        $('.focus-placeholder-behaviour input, .focus-placeholder-behaviour textarea').focus(function(){
            $(this).data('placeholder',$(this).attr('placeholder')).attr('placeholder','');
        }).blur(function(){
            $(this).attr('placeholder',$(this).data('placeholder'));
        });

    }

    function _initUploadTriggers(){
        $('.upload-trigger-btn').click( function(e){
            e.preventDefault();
            var btn = $(this);
            var chooserId = $(btn.attr('data-file-chooser'));
            chooserId.trigger('click');
            chooserId.change(function(){
                var fileName = removeFakePath(chooserId.val())
                btn.text(fileName);
            });
        });

    };

    /**
     * Removes the fake path added by most browser to the name of a file to upload.
     * @param path
     * @returns string
     */
    function removeFakePath(path){
        return path.replace(/C:\\fakepath\\/i, '');
    }

    function _init() {
        $(document).ready(function () {
            _focusPlaceholderBehaviour();
            _initUploadTriggers();
            _initAjaxFormSubmit();
        });
    }

    _init();

    return {
        resetFormFields: resetFormFields,
        resetSectionFormFields : resetSectionFormFields,
        ajaxFormSubmit: ajaxFormSubmit,
        ajaxSelectRemoteOptionsLoad: ajaxSelectRemoteOptionsLoad,
        getErrors: getErrors,
        removeFakePath: removeFakePath
    };
})();