modules.validator = (function () {

    function setDefaults(){

        $.extend($.validator.messages, {
            required: "Campo de preenchimento obrigat&oacute;rio.",
            remote: "Por favor, corrija este campo.",
            email: "Por favor, introduza um endere&ccedil;o eletr&oacute;nico v&aacute;lido.",
            url: "Por favor, introduza um URL v&aacute;lido.",
            date: "Por favor, introduza uma data v&aacute;lida.",
            dateISO: "Por favor, introduza uma data v&aacute;lida (ISO).",
            number: "Por favor, introduza um n&uacute;mero v&aacute;lido.",
            digits: "Por favor, introduza apenas d&iacute;gitos.",
            creditcard: "Por favor, introduza um n&uacute;mero de cart&atilde;o de cr&eacute;dito v&aacute;lido.",
            equalTo: "Por favor, introduza de novo o mesmo valor.",
            extension: "Por favor, introduza um ficheiro com uma extens&atilde;o v&aacute;lida.",
            maxlength: $.validator.format("Por favor, n&atilde;o introduza mais do que {0} caracteres."),
            minlength: $.validator.format("Por favor, introduza pelo menos {0} caracteres."),
            rangelength: $.validator.format("Por favor, introduza entre {0} e {1} caracteres."),
            range: $.validator.format("Por favor, introduza um valor entre {0} e {1}."),
            max: $.validator.format("Por favor, introduza um valor menor ou igual a {0}."),
            min: $.validator.format("Por favor, introduza um valor maior ou igual a {0}."),
            nifES: "Por favor, introduza um NIF v&aacute;lido.",
            nieES: "Por favor, introduza um NIE v&aacute;lido.",
            cifES: "Por favor, introduza um CIF v&aacute;lido."
        });
	
        $.validator.setDefaults({
            ignore: ":hidden:not(.force-validation)"
        });

        $.validator.addMethod("email", function(value, element) {
            return this.optional(element) || /^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9_]@([a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z])/.test(value);
        });

        $.validator.addMethod('maxfilesize', function (value, element, param) {
            // param = size (in bytes)
            // element = element to validate (<input>)
            // value = value of the element (file name)
            return this.optional(element) || (element.files[0].size <= param)
        }, "O ficheiro selecionado é demasiado grande. ");

        $.validator.addMethod("reCaptchaMethod", function(value, element) {
            if (grecaptcha.getResponse() == '') {
                return false;
            } else {
                $(element).val(grecaptcha.getResponse());
                return true;
            }
        }, "Preencha a caixa reCAPTCHA.");

    }

    function prepareValidator(form){

        setDefaults();

        var validator = form.validate({
            errorPlacement: function(error, element) {
                error.insertAfter(element.closest("div"));
            },
            submitHandler: function(f) {

                var objForm = $(f);

                if (typeof objForm.attr('data-ajax-form-submit') !== typeof undefined && objForm.attr('data-ajax-form-submit') !== false) {

                    modules.form.ajaxFormSubmit(objForm);

                }
            }
        });

        form.find('input, textarea').on('blur keyup', function() {

            var f = $(this).closest('form')

            var submitBtn = f.find('.button.submit');

            if(f.validate().checkForm()) {
                submitBtn.removeClass('disabled');
            }else {
                submitBtn.addClass('disabled');
            }
        });

        return validator;
    }

    return {
        prepareValidator: prepareValidator,
        setDefaults: setDefaults
    };
})();