modules.effects = (function() {

    var effectsOptions = {
        millisecondsToFadeInHoverSwipe: 2000,
        millisecondsToFadeInWhenVisible: 3000,
        millisecondsToFadeInOnSwipe: 2000
    };

    function fadeInOnSwipe() {

        var element = $('body'),
            elementsShow = element.find('.effect-fios:not(.hide)'),
            elementsHide = element.find('.effect-fios.hide');

        elementsHide.addClass('effect-fios-hidden');

        elementsShow.removeClass('effect-fios-hidden');

        setTimeout(function() {

            elementsShow.addClass('effect-fios-hidden');

            element.on('touchstart', function() {
                setTimeout(function(){
                    elementsShow.removeClass('effect-fios-hidden');
                }, 250);

            });

            element.on('touchend', function() {
                var _element = $(this);
                elementsShow.removeClass('effect-fios-show');
                _element.attr("data-js-effect-touchend", Date.now());

                setTimeout(function() {
                    var currentTs = Date.now(),
                        diff = currentTs - parseInt(_element.attr("data-js-effect-touchend"));
                    if (diff >= effectsOptions.millisecondsToFadeInOnSwipe) {
                        if (!elementsShow.hasClass('effect-fios-show')) {
                            elementsShow.addClass('effect-fios-hidden');
                        }
                    }
                }, effectsOptions.millisecondsToFadeInOnSwipe);
            });

        }, effectsOptions.millisecondsToFadeInOnSwipe);
    }

    function fadeInOnHoverSwipe(elementsInteract, childrenSelector) {
        setTimeout(function() {
            $(elementsInteract).each(function() {
                var element = $(this),
                    elementsShow = element.find(childrenSelector);
                elementsShow.addClass("effect-fihs");

                //Hides on start if it's not hovering element
                if (!element.is(":hover")) {
                    elementsShow.addClass('effect-fihs-hidden');
                }

                $(element).hover(
                    function() {
                        elementsShow.removeClass('effect-fihs-hidden');
                    },
                    function() {
                        elementsShow.addClass('effect-fihs-hidden');
                    });

                $(element).on('touchstart', function() {
                    elementsShow.removeClass('effect-fihs-hidden').addClass('effect-fihs-show');
                });

                $(element).on('touchend', function() {
                    var element = $(this);
                    elementsShow.removeClass('effect-fihs-show');
                    element.attr("data-js-effect-touchend", Date.now());

                    setTimeout(function() {
                        var currentTs = Date.now(),
                            diff = currentTs - parseInt(element.attr("data-js-effect-touchend"));
                        if (diff >= effectsOptions.millisecondsToFadeInHoverSwipe) {
                            if (!elementsShow.hasClass('effect-fihs-show')) {
                                elementsShow.addClass('effect-fihs-hidden');
                            }
                        }
                    }, effectsOptions.millisecondsToFadeInHoverSwipe);
                });
            });

        }, effectsOptions.millisecondsToFadeInHoverSwipe);
    }

    function fadeInWhenVisible(elementsInteract, childrenSelector) {

        function checkChanges() {

            elementsInteract.each(function() {
                var interactElement = $(this),
                    isInteractElementVisible = interactElement.position().top + interactElement.height() > $(window).scrollTop(),
                    lastCheckVisible = interactElement.hasClass("last-visible");

                if (isInteractElementVisible && !lastCheckVisible) {
                    //if was not visible and now is
                    interactElement.addClass("last-visible");
                    elementsInteract.find(childrenSelector).removeClass("effect-fiv-hidden");

                    setTimeout(function() {
                        elementsInteract.find(childrenSelector).addClass("effect-fiv-hidden");
                    }, effectsOptions.millisecondsToFadeInWhenVisible);


                } else if (!isInteractElementVisible && lastCheckVisible) {
                    //if was visible and now it's not
                    interactElement.removeClass("last-visible");
                }

            });
        }

        //Page load Check
        checkChanges();

        $(window).on("scroll", function() {
            checkChanges();
        });
    }

    function fadeInFadeOutOnLoad(element){
        setTimeout(function() {
            element.fadeOut()
        }, 700);
    }

    function _init(){
        $(document).ready(function(e){
            fadeInOnSwipe();
        });
    }

    _init();

    return {
        fadeInOnHoverSwipe: fadeInOnHoverSwipe,
        fadeInWhenVisible: fadeInWhenVisible,
        fadeInFadeOutOnLoad: fadeInFadeOutOnLoad
    };

})();
