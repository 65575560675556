modules.reseller = (function () {

    var moduleOptions = {
        formSelector: "#reseller-authentication-form"
    };

    function _clearErrors() {
        var form = $(moduleOptions.formSelector);
        form.find("input, .select-div").removeClass("error");
        form.removeClass("error");
    }

    function _initForm() {
        if($('#reseller-modal').length){

            $('.reseller-code-select').data('select2').$dropdown.addClass("reseller-dropdown");
            var form = $(moduleOptions.formSelector);

            form.find("select").on("change", function(){
                if($(this).hasClass("error")) {
                    form.valid();
                }                
            });

            form.validate({
                invalidHandler: function(form, validator) {
                    var errorListLength = validator.errorList.length;
                    // Clear all errors
                    _clearErrors();
                    //Add proper error class to fields if any
                    for (var i=0;i<errorListLength;i++){
                        var el = $(validator.errorList[i].element);
                        if(el.is("select")) {
                            el.closest(".select-div").addClass("error");
                        } else if(el.is("input")) {
                            el.addClass("error");
                        }
                    }
                },
                submitHandler: function(){

                    $.ajax({
                            type: 'POST',
                            url: modules.main.updateUrlWithQueryString(form.attr('action'), form.serialize()),
                            beforeSend: function (xhr) {
                                form.removeClass("error");
                            }
                        })
                        .done(function (data, textStatus, jqXHR) {

                            // Is the redirect url? Redirect the user, otherwise show the erro
                            if(data && data.hasOwnProperty('redirect_url') && data.redirect_url){
                                window.location.href = data.redirect_url;
                            }else{
                                form.addClass("error");
                            }

                        })
                        .fail(function (jqXHR, textStatus, errorThrown) {
                            form.addClass("error");
                        });

                    return false;
                }
            });
        }

    }

    function _init() {
        $(document).ready(function () {
            _initForm();
        });
    }

    _init();
    return {};
})();
