/**
 * Created by alves on 02-12-2015.
 */
modules.main = (function () {

    var options = {
            scrollToTime: 1000,
            numberFormat: {
                decimals: $('#number_format_default_decimals').val(),
                decPoint: $('#number_format_default_dec_point').val(),
                thousandsSep: $('#number_format_default_thousands_sep').val()
            },
            currency: $('#currency_default').val(),
            environment: $('#app_environment').val()
        },
        constants = {
            xsEndWidth: 767
        }

    /**
     * Checks if the App is on Testing mode.
     * @returns {boolean}
     */
    function isTestEnvironment(){
        return options.environment == 'test';
    }

    /**
     * Strips tags from a string.
     * @param input
     * @param allowed
     */
    function stripTags(input, allowed) {

        allowed = (((allowed || '') + '')
            .toLowerCase()
            .match(/<[a-z][a-z0-9]*>/g) || [])
            .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
        var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
            commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
        return input.replace(commentsAndPhpTags, '')
            .replace(tags, function($0, $1) {
                return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
            });
    }

    /**
     * Formats a number into money.
     * @param value
     * @param includeCurrency
     * @returns {string}
     */
    function money(value, includeCurrency){
        return "" + numberFormat(value) + ((typeof includeCurrency === 'undefined') ? '' : options.currency);
    }

    /**
     * Formats a Number.
     * @param number
     * @param decimals
     * @param dec_point
     * @param thousands_sep
     * @returns {*|string}
     */
    function numberFormat(number, decimals, dec_point, thousands_sep) {

        number = (number + '')
            .replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? options.numberFormat.decimals : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? options.numberFormat.thousandsSep : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? options.numberFormat.decPoint : dec_point,
            s = '',
            toFixedFix = function(n, prec) {
                var k = Math.pow(10, prec);
                return '' + (Math.round(n * k) / k)
                        .toFixed(prec);
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
            .split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '')
                .length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1)
                .join('0');
        }
        return s.join(dec);
    }

    /**
     * Returns the currency.
     * @returns {*}
     */
    function currency(){

        return options.currency;

    }

    function isElementInViewport(el) {

        if (typeof jQuery === "function" && el instanceof jQuery) {
            el = el[0];
        }

        var rect = el.getBoundingClientRect();
        return rect.bottom > 0 &&
            rect.right > 0 &&
            rect.left < (window.innerWidth || document. documentElement.clientWidth) /*or $(window).width() */ &&
            rect.top < (window.innerHeight || document. documentElement.clientHeight) /*or $(window).height() */;
    }

    function centerItFixedWidth(target, outer){
        var x = outer.width();
        var y = target.outerWidth(true);
        var z = target.index();
        outer.scrollLeft(Math.max(0, (y * z) - (x - y)/2));
    }

    function centerItVariableWidth(target, outer){
        var x = outer.width();
        var y = target.outerWidth(true);
        var z = target.index();
        var q = 0;
        var m = outer.find('li');
        //Just need to add up the width of all the elements before our target.
        for(var i = 0; i < z; i++){
            q+= $(m[i]).outerWidth(true);
        }
        outer.scrollLeft(Math.max(0, q - (x - y)/2));
    }

    function resetSelect2(element) {
        destroySelect2(element);
        initSelect2(element);
    }

    function destroySelect2(element) {
        element.select2("destroy");
    }

    function _initMinPageHeight() {

        var callback = function() {

            //Reset main height
            var mainElem = $("main");
            mainElem.css("height", "auto");

            var documentHeight = $("body").height(),
                windowHeight = $(window).height();

            if (windowHeight > documentHeight) {
                var diff = windowHeight - documentHeight,
                    mainHeight = mainElem.outerHeight(true);
                mainElem.css("height", mainHeight + diff + "px");
            }
        };

        callback();

        $(window).resize(function() {
            callback();
        });
    }

    function initSelect2(element) {
        if(element.size()) {
            element.select2({
                minimumResultsForSearch: Infinity
            });
        }
    }

    function initTooltip(element) {
        element.tooltip();
    }

    function _countRemainingCharacters() {
        $('[data-count-remaining-chars-target]').keyup(function () {
            var textLength = $(this).val().length;
            var maxLength = $(this).attr('maxlength');
            if(!maxLength){
                maxLength = $(this).attr('data-max-length');
            }

            if (typeof maxLength !== typeof undefined && maxLength !== false) {

                var target = $(this).attr('data-count-remaining-chars-target');

                if (textLength >= maxLength) {
                    $(target).text(0);
                } else {
                    var charsRemaining = maxLength - textLength;
                    $(target).text(charsRemaining);
                }
            }
        });
    }

    function getDevice() {
        //var docWidth = $(document).width();
        var less767 = window.matchMedia( "all and (max-width:767px)");
        var btw7681199 = window.matchMedia( "all and (min-width:768px) and (max-width:1199px)" );
        var more1200 = window.matchMedia( "all and (min-width:1200px)" );

        if (less767.matches){
            return 'mobile';
        } else if (btw7681199.matches) {
            return 'tablet';
        } else if (more1200.matches) {
            return 'desktop';
        } else {
            return null;
        }
    }

    function resetRemainingCharacters(element) {

        var maxLength = element.attr('maxlength');
        if(!maxLength){
            maxLength = element.attr('data-max-length');
        }

        if (typeof maxLength !== typeof undefined && maxLength !== false) {
            var target = element.attr('data-count-remaining-chars-target');
            $(target).text(maxLength);
        }
    }

    function cssAnimationsCapable() {
        var animation = false,
            animationstring = 'animation',
            keyframeprefix = '',
            domPrefixes = 'Webkit Moz O ms Khtml'.split(' '), c
        pfx = '',
            elm = document.createElement('div');

        if (elm.style.animationName !== undefined) {
            animation = true;
        }

        if (animation === false) {
            for (var i = 0; i < domPrefixes.length; i++) {
                if (elm.style[ domPrefixes[i] + 'AnimationName' ] !== undefined) {
                    pfx = domPrefixes[ i ];
                    animationstring = pfx + 'Animation';
                    keyframeprefix = '-' + pfx.toLowerCase() + '-';
                    animation = true;
                    break;
                }
            }
        }

        return animation;
    }

    function _initBackToTop() {
        if ($('#back-to-top').length) {
            var scrollTrigger = 400, // px
                backToTop = function () {
                    var scrollTop = $(window).scrollTop();
                    if (scrollTop > scrollTrigger) {
                        $('#back-to-top').addClass('show');
                    } else {
                        $('#back-to-top').removeClass('show');
                    }
                };
            backToTop();
            $(window).on('scroll', function () {
                backToTop();
            });
            $('#back-to-top').on('click', function (e) {
                e.preventDefault();
                $('html,body').animate({
                    scrollTop: 0
                }, 700);
            });
        }
    }

    function _initDeviceType() {
        $(document).ready(function () {
            $('body').attr('data-device', getDevice());
        });
        $(document).resize(function () {
            $('body').attr('data-device', getDevice());
        });
    }

    function _initAccordionMobileScrollTo() {
        $(".collapse.accordion-scroll-to-top-xs").on("shown.bs.collapse", function(){
            if (modules.main.getDevice() === 'mobile') {
                $('html,body').animate({
                    scrollTop: $("a[href='#"+ $(this).attr("id") +"']").offset().top-50
                }, options.scrollToTime); // The number here represents the speed of the scroll in milliseconds
            }
        });

    }

    function _initToggleSeeMore() {

        var initCallback = function() {
            $(".line-clamp[data-vo-toggle]").each(function () {
                var loopElement = $(this),
                    finalHeight = loopElement.height();
                loopElement.attr("data-final-height", finalHeight).addClass("line-clamp-initialized").css("overflow", "hidden");
                var webkitLineClamp = parseInt(loopElement.css("-webkit-line-clamp")),
                    lineHeight = parseInt(loopElement.css("line-height"));
                if(webkitLineClamp*lineHeight >= finalHeight) {
                    //Content is not enough for line clamping. Removes Initialized class and hides show more related
                    var dataVoToggleId = loopElement.attr("data-vo-toggle");
                    //loopElement.removeClass("line-clamp-initialized");
                    $("[data-toggle="+dataVoToggleId+"]").hide();
                }
                loopElement.trigger("line-clamp:initialized");
            });

        }

        if (document.readyState === "complete") {
            initCallback();
        } else {
            $(window).load(function() {
                initCallback();
            });
        }

        $(document).on("click", '.vo-toggle', function () {

            var currentElement = $(this),
                toggleId = currentElement.attr("data-toggle"),
                currentText, textElement;

            //Toggle class hidden on elements with data-vo-toggle=toggleId
            $("[data-vo-toggle='" + toggleId + "']").each(function () {
                var loopElement = $(this);
                if (loopElement.hasClass('slideToggle')) {
                    loopElement.slideToggle('slow', function () {
                        loopElement.trigger('toggle-see-more:done', [currentElement]);
                    });
                } else if(loopElement.hasClass('line-clamp-initialized')) {
                    var finalHeight = loopElement.attr("data-final-height"),
                        isOpen = loopElement.hasClass("open"),
                        startingHeight = loopElement.attr("data-starting-height");

                    if(typeof startingHeight === "undefined" && !isOpen){
                        startingHeight = loopElement.height();
                        loopElement.attr("data-starting-height", startingHeight);
                    }


                    if(isOpen){
                        //Closes
                        loopElement.css("height", finalHeight+"px");
                        loopElement.animate({"height": startingHeight}, "slow" , function(){
                            loopElement.addClass("line-clamp");
                            $(this).trigger("line-clamp:closed");
                        });
                    } else {
                        //Open
                        loopElement.css("height", startingHeight+"px");
                        loopElement.removeClass("line-clamp");
                        loopElement.animate({"height": finalHeight}, "slow");
                        $(this).trigger("line-clamp:open");
                    }
                    loopElement.toggleClass("open");
                } else {
                    loopElement.toggleClass('hidden-see-more');
                    loopElement.trigger('toggle-see-more:done', [currentElement]);
                }
            });

            //Toggle Text of element with data-toggle-text
            if (typeof currentElement.attr('data-toggle-text') !== 'undefined') {
                textElement = currentElement;
            } else {
                textElement = currentElement.find('[data-toggle-text]')[0];
            }
            textElement = $(textElement);
            currentText = textElement.text();
            textElement.text(textElement.attr('data-toggle-text')).attr('data-toggle-text', currentText);

        });
    }

    function _initClientRules() {

        _initMinPageHeight();

        // Initial Position of the Horizontal Scrollers
        $('[data-set-position]').each(function(i, obj) {
            centerItVariableWidth($($(this).attr('data-set-position')), $(this));
        });

        //Show max of one div related to the newsletter per page
        var subscribeContainersLength = $(".js-newsletter").length;
        if (subscribeContainersLength > 1) {
            $("footer .js-newsletter").addClass("hide");
        }

        /**
         * On submit of the search form.
         */
        $(document).on('submit', '#search-form', function(e){
            if ($('#search-form-query').val() === '') {
                return false;
            } else {
                // Track the search
                var search = $('#search-form-query').val();
                if(search){
                    modules.facebookPixelTracking.search(search);
                }
            }
        });

        /**
         * On Submit of the infopedia form we need to append the search word to the action url.
         */
        $('.infopedia-search-word-form').on('submit', function (e) {
            var _this = $(this);
            var search = _this.find('[name="word"]').val();
            modules.facebookPixelTracking.search(search);
            window.open(_this.attr('action') + search, '_blank');
            return false;
        });

        /**
         * Rules for links:
         *  - All extenal links open in a new tab
         *  - All links with "#" have a smooth scroll
         *  - Menu Links(header and footer) with children should not open
         *  - All links that start with "?"
         */
        $(document).on('click', 'a', function (e) {
            var _this = $(this),
                device = getDevice();

            if (!((device === "desktop" && _this.hasClass("js-link-block-desktop"))||
                (device === "tablet" && _this.hasClass("js-link-block-tablet"))||
                (device === "mobile" && _this.hasClass("js-link-block-mobile")))
            ) {
                var href = _this.attr("href"),
                    simpleHostname = window.location.hostname.replace(/^(www\.)/, ""),
                    externalLinkRegex = new RegExp("^(\/|javascript|#|https?://(www.)?" + simpleHostname + ")");

                if (!_this.hasClass('no-redirect-link') && !externalLinkRegex.test(href)) {
                    window.open(href, '_blank');
                    e.preventDefault();
                } else if (
                    (!_this.hasClass("no-scroll") && !(_this.hasClass("no-scroll-xs") && document.body.clientWidth <= constants.xsEndWidth)) &&
                    href.charAt(0) === "#"
                ) {
                    var target = $(href);
                    if (target.length) {
                        $('html,body').animate({
                            scrollTop: target.offset().top
                        }, options.scrollToTime); // The number here represents the speed of the scroll in milliseconds
                        return false;
                    }
                }
            } else {
                return false;
            }
        });

    }

    /**
     * Truncates a string for a specific number of characters.
     * @param string text
     * @param int length
     * @param string end
     * @returns string
     */
    function truncateString(text, length, end) {

        if (text.length > length) {
            text = text.slice(0, length);
            if (end) {
                text += end;
            }
        }

        return text;
    }

    /**
     * Toggles class on all elements with class "toggle-class-effect" when class "toggle-class" is clicked.
     * Attributes "data-class-a" and "data-class-b" should contain the classes names on the element with the "toggle-class-effect" class.
     */
    function _initToggleClass() {
        $(".toggle-class").on("click", function () {
            var element = $(this),
                elements = [element];

            if (!element.hasClass("toggle-class-effect")) {
                elements = element.find(".toggle-class-effect");
            }

            elements.each(function () {
                var loopElement = $(this),
                    classA = loopElement.attr("data-class-a"),
                    classB = loopElement.attr("data-class-b");
                if (loopElement.hasClass(classA)) {
                    loopElement.removeClass(classA).addClass(classB);
                } else {
                    loopElement.removeClass(classB).addClass(classA);
                }
            });
        });
    }

    /**
     * Initializes Slick Carousel
     * @param array carousels Array of objects foreach carousel
     */

    function initCarousels(carousels) {
        var carouselsLength = carousels.length;
        for (var i = 0; i < carouselsLength; i++) {
            var carouselElement = carousels[i],
                jQueryCarouselElement = $(carouselElement.selector);

            jQueryCarouselElement.slick(carouselElement.initObject);
            jQueryCarouselElement.removeClass("vo-carousel-load-fix").find(".vo-carousel-load-fix").removeClass("vo-carousel-load-fix");

            //Add Events
            if(typeof carouselElement.events !== "undefined") {
                for (var property in carouselElement.events) {
                    if (carouselElement.events.hasOwnProperty(property)) {
                        var callback = carouselElement.events[property];
                        jQueryCarouselElement.on(property, callback);
                    }
                }
            }

        }
        modules.effects.fadeInOnHoverSwipe($('.effect-fihs-action'), '.effect-fihs-target');

    }

    /**
     * Destroys Slick Carousel
     * @param array carousels Array of objects foreach carousel. The same used when initing carousel
     */
    function destroyCarousels(carousels) {
        var carouselsLength = carousels.length;
        for (var i = 0; i < carouselsLength; i++) {
            var carouselElement = $(carousels[i].selector);
            if(carouselElement.hasClass('slick-initialized')){
                carouselElement.slick('unslick');
            }
        }

    }

    function scrollToElement(element, addHeaderHeight, timeScroll) {

        if (typeof timeScroll === "undefined") {
            timeScroll = 700;
        }

        var finalValue = element.offset().top;
        if(addHeaderHeight === true) {
            finalValue -= $('body > header').height();
        }
        if (element.length) {
            $('html,body').animate({
                scrollTop: finalValue
            }, timeScroll);
        }

    }

    function updateUrlWithQueryString(url, queryString) {

        url += (url.match(/\?/) ? '&' : '?') + queryString;

        return url;
    }

    /**
     * Change URL Parameters.
     *
     * @param string url
     * @param string param
     * @param string paramVal
     * @returns string
     */
    function updateUrlParameter(url, param, paramVal){

        var TheAnchor = null;
        var newAdditionalURL = "";
        var tempArray = url.split("?");
        var baseURL = tempArray[0];
        var additionalURL = tempArray[1];
        var temp = "";

        if (additionalURL)
        {
            var tmpAnchor = additionalURL.split("#");
            var TheParams = tmpAnchor[0];
            TheAnchor = tmpAnchor[1];
            if(TheAnchor)
                additionalURL = TheParams;

            tempArray = additionalURL.split("&");

            for (i=0; i<tempArray.length; i++)
            {
                if(tempArray[i].split('=')[0] != param)
                {
                    newAdditionalURL += temp + tempArray[i];
                    temp = "&";
                }
            }
        }
        else
        {
            var tmpAnchor = baseURL.split("#");
            var TheParams = tmpAnchor[0];
            TheAnchor  = tmpAnchor[1];

            if(TheParams)
                baseURL = TheParams;
        }

        if(TheAnchor)
            paramVal += "#" + TheAnchor;

        var rows_txt = temp + "" + param + "=" + paramVal;
        return baseURL + "?" + newAdditionalURL + rows_txt;
    }

    function _initFallbacks() {
        if (!Date.now) {
            Date.now = function () {
                return new Date().getTime();
            }
        }
    }

    function _initEnvironmentWarnings(){

        $(document).on('click', '.env-warning-wrapper', function(e){
            e.preventDefault();
            $(this).remove();
        });

    }

    function _initGA4() {
        pt.portoeditora && pt.gpe.generic && pt.gpe.generic.ga4.init(pt.portoeditora.cookies.getConsent("_ga"), null);
    }

    function _init() {
        $(document).ready(function () {
            _initFallbacks(); //Keep it as first function being called
            _initClientRules();
            _initToggleSeeMore();
            _initAccordionMobileScrollTo();
            _countRemainingCharacters();
            _initDeviceType();
            _initBackToTop();
            _initToggleClass();
            initSelect2($('.select2'));
            _initEnvironmentWarnings();
            _initGA4();
        });
    }

    _init();

    return {
        cssAnimationsCapable: cssAnimationsCapable(),
        getDevice: getDevice,
        resetRemainingCharacters: resetRemainingCharacters,
        initTooltip: initTooltip,
        truncateString: truncateString,
        initCarousels: initCarousels,
        destroyCarousels: destroyCarousels,
        initSelect2: initSelect2,
        destroySelect2: destroySelect2,
        resetSelect2: resetSelect2,
        updateUrlWithQueryString: updateUrlWithQueryString,
        scrollToElement: scrollToElement,
        isElementInViewport: isElementInViewport,
        centerItFixedWidth: centerItFixedWidth,
        centerItVariableWidth: centerItVariableWidth,
        updateUrlParameter: updateUrlParameter,
        stripTags: stripTags,
        numberFormat: numberFormat,
        currency: currency,
        money: money,
        isTestEnvironment: isTestEnvironment
    };
})();
