modules.menu = (function () {

    var options = {
        timeToToggle: 200
    };

    function openMenu(menuOptions) {
        var menuElement = $(menuOptions.menuLinksSelector);
        // If menu is not toggling
        if (!menuElement.hasClass(menuOptions.togglingClass)) {
            menuElement.addClass(menuOptions.togglingClass);
            $(menuOptions.showMenuSelector).addClass(menuOptions.hiddenElementClass);
            $(menuOptions.hideMenuSelector).removeClass(menuOptions.hiddenElementClass);
            $('body').addClass('lock');
            //@todo: Animation using Js, implement css transitions
            menuElement.animate({
                height: _getMenuHeight(menuElement) + "px"
            }, options.timeToToggle, function () {
                // Animation complete.
                menuElement.css("height", "auto").removeClass(menuOptions.togglingClass);
            });
        }
    }

    function closeMenu(menuOptions, callback) {
        var menuElement = $(menuOptions.menuLinksSelector);
        if (!menuElement.hasClass(menuOptions.togglingClass) && menuElement.height() !== 0) {
            menuElement.addClass(menuOptions.togglingClass);
            $(menuOptions.showMenuSelector).removeClass(menuOptions.hiddenElementClass);
            $(menuOptions.hideMenuSelector).addClass(menuOptions.hiddenElementClass);
            //@todo: Animation using Js, implement css transitions
            menuElement.css("height", menuElement.height() + "px")
                    .animate({
                        height: modules.main.getDevice() === "desktop" ? "50px" : "0"
                    }, options.timeToToggle, function () {
                        // Animation complete.
                        $(this).css('height', '0');
                        menuElement.removeClass(menuOptions.togglingClass);
                        if(typeof callback === "function") {
                            callback();
                        } else {
                            $('body').removeClass('lock');
                        }
                    });
        } else if(typeof callback === "function") {
            $('body').addClass('lock');
            setTimeout(callback, 100);
        }
    }

    function _scrollTopElement(element) {
        var navElem = $("body > header > nav");
        if(navElem.size()) {
            $('body > header nav, body > header').animate({
                scrollTop: navElem.scrollTop() + ($(element).offset().top - navElem.offset().top)
            }, 250);
        }
    }

    function menuOptionClick(menuOptions, element, scrollToTop, callback) {

        element = $(element);

        if (element.hasClass(menuOptions.menuOptionOpenClass)) {
            //Close Option
            element.removeClass(menuOptions.menuOptionOpenClass).find(menuOptions.arrowSelector).removeClass(menuOptions.arrowOpenedClass).addClass(menuOptions.arrowClosedClass);
            var id = element.data("id");
            //Close all including children
            var optionsToCloseChildren = $('.' + menuOptions.menuOptionOpenClass + '[data-parent-id="' + id + '"]').toArray(),
                    optionsToHide = $('[data-parent-id="' + id + '"]').toArray();
            while (optionsToCloseChildren.length > 0) {
                var currentOption = $(optionsToCloseChildren.pop()),
                        currentId = currentOption.data('id');
                currentOption.removeClass(menuOptions.menuOptionOpenClass).find(menuOptions.arrowSelector).removeClass(menuOptions.arrowOpenedClass).addClass(menuOptions.arrowClosedClass);
                if (typeof currentId !== "undefined") {
                    optionsToCloseChildren = optionsToCloseChildren.concat($('.' + menuOptions.menuOptionOpenClass + '[data-parent-id="' + currentId + '"]').toArray());
                    optionsToHide = optionsToHide.concat($('[data-parent-id="' + currentId + '"]').toArray());
                }
            }
            $(optionsToHide).addClass(menuOptions.hiddenElementClass);
            if (scrollToTop) {
                _scrollTopElement(element);
            }

            if (callback) {
                callback();
            }
        } else {
            //Open Option
            var id = element.data("id"),
                    parentId = element.data("parent-id"),
                    openOptions = [],
                    afterCloseCallback = function () {
                        element.addClass(menuOptions.menuOptionOpenClass).find(menuOptions.arrowSelector).removeClass(menuOptions.arrowClosedClass).addClass(menuOptions.arrowOpenedClass);
                        $('[data-parent-id="' + id + '"]').removeClass(menuOptions.hiddenElementClass);
                        if (scrollToTop) {
                            _scrollTopElement(element);
                        }
                    };

            if (typeof parentId !== "undefined") {
                //Close all open menu with data-parent-id attr = parentId
                openOptions = $(menuOptions.menuSelector + " [data-parent-id='" + parentId + "']." + menuOptions.menuOptionOpenClass);
            } else {
                //Close all open menu Options lvl-0
                openOptions = $(menuOptions.menuSelector + " .lvl-0." + menuOptions.menuOptionOpenClass);
            }

            if (openOptions.size() > 0) {
                menuOptionClick(menuOptions, openOptions.get(0), false, afterCloseCallback);
            } else {
                afterCloseCallback();
            }
        }
    }

    function _getMenuHeight(elem) {
        var heightCss = elem.css("height"),
                height = elem.css("visibility", "hidden").css("height", "auto").height();
        elem.css("visibility", "visible").css("height", heightCss);
        return height;
    }

    return{
        openMenu: openMenu,
        closeMenu: closeMenu,
        menuOptionClick: menuOptionClick
    };
})();
