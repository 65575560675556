/**
 *
 * Can only be used when using lazyload.js
 * Made to fix behaviour with website carousel
 */

modules.lazyLoad = (function () {

    var options = {
        default:{
            selector: ".lazy"
        }
    }

    function init(jQuerySelector, params) {

        if (typeof jQuerySelector === "undefined") {
            jQuerySelector = $(options.default.selector);
        }

        var defaults = {
            effect : "fadeIn",
            event: "vo-lazy-check-appear",
            threshold : 50,
            failure_limit: 9999
        };

        $.extend(defaults, params);
        jQuerySelector.lazyload(defaults);
    }

    function triggerCheck() {
        $(".lazy:not(.lazy-loaded)").each(function(){
            // inviewport is provided by lazyload.js
            if ($.inviewport(this, {threshold : 0})) {
                $(this).trigger("vo-lazy-check-appear");
            }
        });
    }

    function _init() {

        $(document).ready(function () {

            if (document.readyState === "complete") {
                $(document).trigger("vo-lazy-check");
            } else {
                $(window).load(function() {
                    $(document).trigger("vo-lazy-check");
                });
            }

            $(".other-author-books .more").on("click", function(){
                setTimeout(function(){
                  $(document).trigger("vo-lazy-check");
                }, 100);                
            });

            $(document).on("scroll", function(){
                $(document).trigger("vo-lazy-check");
            }).on("vo-lazy-check", function(){
                triggerCheck();
            }).on("vo-lazy-check-appear", function (e) {
                $(e.target).addClass("lazy-loaded");
            });

            init($(options.default.selector));
        });
    }

    _init();

    return {
        init: init
    };
})();