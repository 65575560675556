/**
 * Created by alves on 02-12-2015.
 */
$(document).ready(function(e){

    $(document).on('click', '[data-load-more]:not(.disabled)', function(e){

        var trigger = $(this);
        trigger.hide();
        var limit = parseInt(trigger.attr('data-limit'));
        var offset = parseInt(trigger.attr('data-offset'));
        
        trigger.trigger('load-more:before');

        var jqxhr = $.get(trigger.attr('data-load-more'), { 'limit': limit, 'offset': offset }, function( data ) {

            // The listeners should do whatever they want with the response!
            trigger.trigger('load-more:done', [data]);
            // Update the offset
            trigger.attr('data-offset', (limit+offset));

            // No more data? Hide the trigger
            if(data.length < limit){
                trigger.fadeOut('slow').remove();
            }

        });

        jqxhr.fail(function( data ) {
            if(trigger){
                trigger.trigger('load-more:fail', [data]);
                trigger.show();
            }
        });

        jqxhr.always(function( data ) {
            if(trigger){
                trigger.trigger('load-more:always', [data]);
                trigger.show();
            }
        });

    });

});
