/**
 * Created by alves on 19-02-2016.
 */
modules.facebookPixelTracking = (function () {

    function _isEnabled(){
        return $('#tracking-facebook-pixel').length ? true : false;
    }

    /**
     * Tracks a search with facebook pixel.
     * @param parameters
     */
    function search($search){

        if(_isEnabled()){
            fbq('track', 'Search', { 'search_string': $search});
        }

    }

    /**
     * Tracks a cart added to the cart.
     * @param parameters
     */
    function addToCart(productId){

        if(_isEnabled()){
            fbq('track', 'AddToCart', { content_ids : [productId] });
        }

    }

    /**
     * Tracks checkout initiation.
     * @param parameters
     */
    function initiateCheckout(){

        if(_isEnabled()){
            fbq('track', 'InitiateCheckout');
        }

    }

    /**
     * Tracks a purchase.
     * @param string value
     * @param string currency
     */
    function purchase(value, currency){
        if(_isEnabled()){
            fbq('track', 'Purchase', { 'value': value, 'currency': currency });
        }
    }

    /**
     * Tracks a complete registration.
     * @param string name
     * @param string status
     */
    function completeRegistration(name, status){

        if(_isEnabled()){
            fbq('track', 'CompleteRegistration', { 'content_name': name, 'status': status });
        }

    }

    return {
        search: search,
        addToCart: addToCart,
        initiateCheckout: initiateCheckout,
        purchase: purchase,
        completeRegistration: completeRegistration
    };
})();
