modules.footer = (function () {

    var sitemapOptions = {
        menuSelector: 'footer .links .menu',
        menuOptionOpenClass: 'open',
        arrowSelector: '.arrow',
        arrowOpenedClass: 'icon-v',
        arrowClosedClass: 'icon-V',
        hiddenElementClass: 'hidden-xs'
    },
    portoEditoraMenuOptions = {
        overlayElementSelector: 'footer .layer-1 .overlay',
        arrowSelector: '.arrow span',
        arrowOpenedClass: 'icon-v',
        arrowClosedClass: 'icon-V'
    };

    function _init() {
        $(document).ready(function () {
            _initPortoEditoraMenu();
            _initSiteMap();
        });
    }

    function _initPortoEditoraMenu() {
        $(portoEditoraMenuOptions.overlayElementSelector).on('click', function () {
            var parent = $(this).closest('.layer-1'),
                    isOpen = parent.hasClass('open');
            if (isOpen) {
                parent.removeClass('open')
                        .find(portoEditoraMenuOptions.arrowSelector)
                        .removeClass(portoEditoraMenuOptions.arrowOpenedClass)
                        .addClass(portoEditoraMenuOptions.arrowClosedClass);

            } else {
                parent.addClass('open')
                        .find(portoEditoraMenuOptions.arrowSelector)
                        .removeClass(portoEditoraMenuOptions.arrowClosedClass)
                        .addClass(portoEditoraMenuOptions.arrowOpenedClass);
                ;
            }
        });
    }

    function _initSiteMap() {
        $(sitemapOptions.menuSelector + " [data-id]").on('click', function () {
            modules.menu.menuOptionClick(sitemapOptions, this, true);
        });
    }

    _init();

    return {};

})();