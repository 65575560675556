modules.thumbnail = (function () {

    function videoInfo(src){

        var youtube = src.match(/\/\/(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/)?([a-z0-9\-\_\%]+)/i);
        var vimeo = src.match(/\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i);
        var dailymotion = src.match(/\/\/(?:www\.)?dai.ly\/([0-9a-z\-_]+)/i);

        if (youtube) {
            return {
                youtube: youtube
            };
        } else if (vimeo) {
            return {
                vimeo: vimeo
            };
        } else if (dailymotion) {
            return {
                dailymotion: dailymotion
            };
        }

    };

    function videoThumb(src, thumbSize, forceSource) {
        
        var isVideo = forceSource || videoInfo(src) || {};

        if (isVideo.youtube) {
            return '//img.youtube.com/vi/' + isVideo.youtube[1] + '/' + thumbSize + '.jpg';
        } else if (isVideo.vimeo) {
            return src.substring(0, src.lastIndexOf("_") + 1) + thumbSize + ".jpg";
        } else if (isVideo.dailymotion) {
            return '//www.dailymotion.com/thumbnail/video/' + isVideo.dailymotion[1];
        }
    }

    function vimeoRealThumb(vimeoVideoId, callback){

        $.getJSON('//www.vimeo.com/api/v2/video/' + vimeoVideoId + '.json?callback=?', {
            format: 'json'
        }, function(data) {
            callback(data);
        });

    }

    /**
     * Generates a Thumbnail for an element.
     * Expects data-attributes:
     * data-generate-video-thumb with the URL for the video
     * @param elem
     */
    function generateVideoThumbnail(elem){

        // For now only works for images. If you need this to other element put here a if is a (div) for example.
        var url = elem.attr('data-generate-video-thumb');

        var isVideo = videoInfo(url) || {};

        var validVideo = false;
        var thumbSize = null;

        if (isVideo.youtube) {
            validVideo = true;
            thumbSize = 'hqdefault';
        } else if (isVideo.vimeo) {
            validVideo = true;
            thumbSize = 'thumbnail_medium';
            vimeoRealThumb(isVideo.vimeo[1], function(data){
                var realThumb = data[0]['thumbnail_medium'];
                if(elem.is('img')){
                    elem.attr('src', realThumb);
                }else{
                    elem.css("background-image", "url("+realThumb+")");
                }
            });
        } else if (isVideo.dailymotion) {
            validVideo = true;
            thumbSize = 'default';
        }
        if(validVideo){
            var thumb = videoThumb(url, thumbSize);
            if(elem.is('img')){
                elem.attr('src', thumb);
            }else{
                elem.css("background-image", "url("+thumb+")");
            }
        }

    }

    function _initThumbnails(){

        $('[data-generate-video-thumb]').each(function(i, obj) {
            generateVideoThumbnail($(this));
        });

    }

    function _init() {
        $(document).ready(function () {
            _initThumbnails();
        });
    }

    _init();

    return {
        videoInfo: videoInfo,
        videoThumb: videoThumb,
        vimeoRealThumb: vimeoRealThumb,
        generateVideoThumbnail: generateVideoThumbnail
    };

})();
